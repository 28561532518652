import {inject} from 'aurelia-framework';
import { FeedbackService } from '../../shared/services/feedbackservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import * as tinymce from 'tinymce';
import {Router} from 'aurelia-router';
import { App } from '../../app';
import {DialogService} from 'aurelia-dialog';
import {SavedDataComponent} from "./fetchsavefeedback/saveddatacomponent";
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";

@inject(FeedbackService, ValidationControllerFactory,DialogService, Router, App,LoginService,JwtService)
export class FeedbackComponent {
    options = [];
    selected = {};
    searchText = '';
    selectAll = true;
    departments = [];
    errors = null;
    routerView = "main-content";
    selectedSentoEmployees = [];
    SelectedSendtoEmployeesIDs = [];
    EmployeeListIDs = [];
    showPopup = false;
    showDropdown = false;
    selectedEmployees = '';
    showEmpDropdown = false;
    selectedOption = '1';
    employeeOrDepartment = '1';
    employeeType;
    selectedOptionLabel = "Feedback";
    FeedbackLabel = "Feedback";
    GrievanceLabel = "Grievance";
    feedbackValue = "";
    grievanceValue = "";
    formType = '';
    emails = '';
    emailsErrors = null;
    selectedFormType = 1;
    editor;
    commentsMessage;
    fileMessage;
    pageSize = 10;
    isFormSubmitted = false;
    constructor(feedbackservice, controllerFactory, dialogService, router, App,loginservice,JwtService) {
        this.feedbackservice = feedbackservice;
        this.controller = controllerFactory.createForCurrentScope();
        this.searchQuery = '';
        this.dropdownOptions = [];
        this.selectedEmployees = [];
        this.selectedSentoEmployees = [];
        this.selectedEmployee = null;
        this.filterEmpOption = '';
        this.dialogService = dialogService;
        this.router = router;
        this.app = App;
        this.loginservice = loginservice;
        this.jwtService = JwtService;
        document.addEventListener('click', this.handleClickOutsideDropdown);
        ValidationRules
            .ensure('title').required().withMessage("Subject is required")
            .maxLength(100)
            .withMessage('Subject should be less than 100 characters.')
            // .ensure('selectedOptions').required().withMessage("Send to filed is required")
            .ensure('emails')
            .satisfies((value) => {
                if (value && value.trim() !== '') {
                    const emails = value.split(',');
                    const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
                    const invalidEmails = [];
                    for (const email of emails) {
                        const trimmedEmail = email.trim();
                        if (!emailRegex.test(trimmedEmail)) {
                            invalidEmails.push(trimmedEmail);
                        }
                    }
                    if (invalidEmails.length > 0 || (emails.length > 1 && value.indexOf(',') === -1)) {
                        this.emailsErrors = ['Invalid email address and Email(s) should be separated by commas'];
                        return false;
                    }
                }
                this.emailsErrors = [];
                return true;
            }).withMessage('Invalid email address(es) and Email(s) should be separated by commas')
            .on(this);
    }
    attached() {

        tinymce.init({
            selector: '#comments',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            setup: editor => {
                editor.on('init', () => {
                    this.editor = editor;
                    this.editorContent = '';
                });
                editor.on('change', () => {
                    this.editorContent = editor.getContent();
                    this.commentsMessage = null;
                });
            },
        });
        this.app.updatePropertyValue(false);
        document.querySelector('router-view').classList.remove(this.routerView);
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage = "Only JPEG, PNG, PDF and DOC files are allowed.";
            } else {
                this.fileMessage = null;

            }
        }, {
            passive: true
        });
        return this.feedbackservice.SendtoEmployeesName()
            .then(data => {
                this.options = data;
            });
    }
    get filteredOptions() {
        const filtered = this.options.filter(option =>
            option.EmployeeName.toLowerCase().includes(this.searchText.toLowerCase())
        );
        if (filtered.length === 0 && this.searchText.trim().length > 0) {
            const errorInfo = {
                error: {
                    message: "No records found"
                }
            };
            this.searchTextError = [errorInfo];
        } else {
            this.searchTextError = [];
        }
        return filtered;
    }
    updateLabels(id) {
        const selectedRadio = document.querySelector('input[name="options"]:checked');
        let selectedOption = selectedRadio.value;
        let formType = selectedOption;
        this.selectedFormType = id;
        if (selectedOption === "1") {
            this.selectedOptionLabel = "feedback";
            this.FeedbackLabel = "Feedback";
            this.GrievanceLabel = "Feedback";
        } else if (selectedOption === "2") {
            this.selectedOptionLabel = "Grievance";
            this.FeedbackLabel = "Grievance";
            this.GrievanceLabel = "Grievance";
        }
    }
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }
    handleClickOutsideDropdown = (event) => {
        const dropdownElement = document.querySelector('.dropdown-menu');
        const buttonElement = document.querySelector('.sendto');
        if (
            dropdownElement &&
            !dropdownElement.contains(event.target) &&
            !buttonElement.contains(event.target)
        ) {
            this.closeDropdown();
        }
    };

    closeDropdown = () => {
        this.showDropdown = false;
    };
    selectOption(checked, option) {
        this.selected[option.EmployeeID] = checked;
        this.selectedOptions = this.options.filter(option => this.selected[option.EmployeeID]);
        if (checked) {
            this.SendToEmployeeSelectedIds = option.EmployeeID;
            this.SelectedSendtoEmployeesIDs.push(this.SendToEmployeeSelectedIds);
            this.selectedSentoEmployees.push(option.EmployeeName);
        } else {
            const index = this.SelectedSendtoEmployeesIDs.indexOf(option.EmployeeID);
            if (index > -1) {
                this.SelectedSendtoEmployeesIDs.splice(index, 1);
            }
            const nameIndex = this.selectedSentoEmployees.indexOf(option.EmployeeName);
            if (nameIndex > -1) {
                this.selectedSentoEmployees.splice(nameIndex, 1);
            }
        }
    }
    isSelected(option) {
        return this.selected[option];
    }
    selectAllOptions() {
        Object.keys(this.selected).forEach(option => this.selected[option] = this.selectAll);
        this.selectedSentoEmployees = this.options.map(option => option.EmployeeName);
        if (this.selectAll) {
            this.selectedSentoEmployees = this.options.map(option => option.EmployeeName);
        } else {
            this.selectedSentoEmployees = [];
        }
        this.selectedOptions = this.options.filter(option => this.selected[option.EmployeeID]);
        this.selectAll = !this.selectAll;
    }
    async onEmployeeSelected() {
        this.employeeOrDepartment = '1';
        this.employeeType = 1;
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.feedbackservice.EmployeeNamesForDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "No records found"
                        }
                    };
                    this.searchQueryError = [errorInfo];
                } else {
                    this.searchQueryError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }

    selectEmployee(EmpOption) {
        const modifiedOption = {
            ...EmpOption,
            ID: EmpOption.EmployeeID
        };
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }
    addToSelectedOptions(id) {
        if (!this.searchQuery) {
            const errorInfo = {
                error: {
                    message: "Please select an employee"
                }
            };
            this.searchQueryError = [errorInfo];
            return;
        }
        this.feedbackservice.GetEmployeesForAdd(id).then(data => {
            const selectedEmployee = this.selectedEmployees.find(employee => employee.EmployeeID === data.EmployeeID);
            if (selectedEmployee) {
                const errorInfo = {
                    error: {
                        message: "Employee name already exists"
                    }
                };
                this.searchQueryError = [errorInfo];
            } else {
                this.searchQueryError = [];
                this.Ids = data.EmployeeID;
                this.EmployeeListIDs.push(this.Ids);
                this.selectedEmployees.push(data);
                this.searchQuery = '';
            }
        });
    }
    GetEmployeeNamesForGrid(id) {
        this.feedbackservice.GetEmployeesForAdd(id)
            .then(data => {
                this.selectedEmployees.push(data);
            });
    }
    deleteEmployee(emp) {
        const index = this.selectedEmployees.indexOf(emp);
        if (index !== -1) {
            this.selectedEmployees.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.EmployeeListIDs = this.EmployeeListIDs.filter(id => id !== deletedIds);
        this.Ids = this.EmployeeListIDs;
    }
    onDepartmentSelected() {
        this.employeeOrDepartment = '2';
        this.employeeType = 2;
        return this.feedbackservice.DepartmentsForDropdown()
            .then(response => {
                this.departments = response;
                return false;
            });
    }
    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }
    submitForm() {
        event.preventDefault();
        if (this.fileMessage) {
            return false;
        }
        this.errors = null;
        this.controller.validate()
            .then(result => {
                if (result.valid) {
                    if (this.editorContent === '') {
                        this.commentsMessage = "Feedback/grievance field is required.";
                        return false;
                    }
                    let feedbackData = {
                        FeedbackorGrievance: this.title,
                        Comments: this.editorContent,
                        DocumentPath: this.file,
                        DepartmentID: this.selectedDepartment,
                        EmployeeType: this.employeeType,
                        Employeelist: this.EmployeeListIDs,
                        SendToList: this.SelectedSendtoEmployeesIDs,
                        EmployeeEmailIds: this.emails,
                        Type: this.selectedFormType,
                        DocumentName: this.DocumentName,
                    };
                    this.isFormSubmitted = true;
                    this.feedbackservice.SaveFeedBack(feedbackData).then((response) => {
                        this.GrievanceID = response;
                        this.feedbackservice.ViewFeedbackData(this.GrievanceID)
                            .then(data => {
                                this.viewdata = data;
                                this.openDialogBox(this.viewdata );
                            });
                        this.selectedOptionLabel = '';
                        this.title = '';
                        this.DocumentName = '';
                        this.selectedOptions = '';
                        this.SendToEmployeeSelectedIds = '';
                        this.selectedSentoEmployees = '';
                         this.searchQuery = '';
                         this.selected = {};
                        this.editorContent = '';
                        this.department = '';
                        this.selectedDepartment = null;

                        this.emails = '';
                        const fileInput = document.querySelector('input[type=file]');
                        if (fileInput) {
                            fileInput.value = '';
                        }
                        if (this.editor) {
                            this.editor.setContent('');
                            this.editor = null;
                        }
                        this.editorContent = '';
                        this.selectedEmployees = [];
                        this.isFormSubmitted = false;
                        setTimeout(() => {
                            this.message = null
                        }, 3000);
                        this.showPopup = true;
                    });
                } else {
                    this.errors = "Invalid credentials";
                }
            })
            .catch(promise => {
                promise.then(err => this.errors = err.errors)
            });
    }
    openDialogBox(data) {
        this.dialogService.open({
            title: 'Saved Data',
            viewModel: SavedDataComponent,
            model: data,
        }).then(dialogResult => {
        });
    }
    detached() {
        tinymce.remove(this.editor);
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    }
    cancle() {
        this.router.navigateToRoute('login');
    }
}
