import { OrganizationService } from "../../shared/services/organizationservice";
import {inject} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../shared/services/leavemanagementservice";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { Errormodal } from "./errormodal/errormodal";
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";
import {Router} from 'aurelia-router';
import { HttpClient } from 'aurelia-fetch-client';
import { constants } from "../../shared/services/constants";

@inject(OrganizationService,DialogController,ValidationControllerFactory,DialogService,LoginService,JwtService,LeavemanagementService,Router,Element)
export class Hierarchychartcomponent {

  employeeOrDepartment = 'employee';
  showEmpDropdown = true;
  view='directReport';
  showTree = false;
  showDirect = true;
  showRM = true;
  treeChart = [];
  managerChild = [];
  ManagerId = '';
  employeeChildTree = [];
  filteredResults = [];
  employeeTree = [];
  searchTerm = '';
  showDropdown = false;
  isFirstSelection = true;
  manager = "manager"; // Default value for the "Manager" radio button
  employee = "employee"; // Default value for the "Employee" radio button
  manageView = true;
  employeeView = false;
  searchlabel = "Reporting Manager";
  selectedView = null;
  showPrint = false;
  SiteMapId=constants.hierarchychartSiteMapID;


  constructor(OrganizationService,DialogController,ValidationControllerFactory,DialogService,loginservice,JwtService,LeavemanagementService,router,element) {
    this.organizationService = OrganizationService;
    this.controller = DialogController;
    this.controllerFactory = ValidationControllerFactory;
    this.dialogService = DialogService;
    this.leavemanagementService = LeavemanagementService;
    this.element = element;
    this.dropdownOptions = [];
    this.managerTree = [];
    this.managerResources = [];
    this.resourceEmpIds = [];
    this.resourceData = [];
    this.selectedEmployee = null;
    this.clicked = false;
      this.jwtService = JwtService;
      this.loginservice = loginservice;
      this.router = router;
      this.httpClient = new HttpClient();
      this.empid = this.jwtService.getEmployeeId();
    ValidationRules
      .ensure('searchManager').required().withMessage('Reporting manager is required')
  }

  getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.organization');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.organization .sidebar-sub .hierarcyChart a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

  async attached() {
      this.getCurrentRouteURL();
      // if(this.jwtService.getToken() === undefined){
      //     this.loginservice.purgeAuth();
      //     this.router.navigateToRoute('login')
      // }
    this.allEmployees = await this.organizationService.GetEmployeemasterDetails();
    this.reportingManagerHierarchy = await this.organizationService.GetManagerHierarchy();
    this.managerHierarchy = this.reportingManagerHierarchy.RepList;
    this.managerdropdown = await this.organizationService.GetReportingManagersearch();
    this.addToRecentItems();
  }

    onEmployeeSelected() {
      this.employeeOrDepartment = 'employee';
      this.employeeTree = [];
      this.managerTree = [];
      const element = document.querySelector('.popup');
      this.clicked=false;
      this.showTree = false;

        if (element) {
            while (element?.classList.length > 0) {
                element?.classList.remove(element?.classList[0]);
            }
        }
      document.getElementById('popup').classList.remove("toogleEmployee");
      }
  
    onDepartmentSelected() {
        this.employeeOrDepartment = 'department';
        this.showTree = false;
        this.showPrint = false;
        this.searchTerm = '';
        this.viewType=null;
        this.view= 'directReport';
        this.searchManager=null;
        this.managerTree = [];
        this.childEmployees=[]
        document.getElementById('popup').classList.add("toogleEmployee");
        document.getElementById('popup').classList.remove("directReport");
      document.getElementById('popup').classList.remove("managerHierarchy");
      }

    //   const result = await this.dialogService.open({
    //     viewModel: AlertpopupComponent,
    //     model: "",
    //  }).whenClosed(response => response);
    //  if (result.wasCancelled) {
    //     return;
    //  }
  
  async  managerSearch(){
      if (this.searchManager) {
        this.showEmpDropdown = true;
        try {
        const filterOption = this.searchManager.toLowerCase();
        this.dropdownOptions = this.managerdropdown.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
        } catch (error) {
        //console.error(error);
        }
        } else {
        this.showEmpDropdown = false;
        this.dropdownOptions = [];
        }
    }
    
    selectManager(EmpOption) {
      const modifiedOption = { ...EmpOption, ID: EmpOption.EmployeeID };
      this.ManagerId = EmpOption.EmployeeID;      
      this.searchManager = modifiedOption.EmployeeName;      
      this.showEmpDropdown = false;
      this.ManagerError = '';
      this.selectedEmployee = modifiedOption;
      }     

      async managergenerate(view, employeeId) {
        if (this.searchManager == undefined || this.searchManager == '') {
          this.ManagerError = 'Reporting manager is required.';
          return;
        }
      
        if (this.dropdownOptions.length < 1) {
          const result = await this.dialogService.open({
            viewModel: Errormodal,
            model: "",
          }).whenClosed(response => response);
          
          if (result.wasCancelled) {
            this.searchManager = '';
            return;
          }
        }
      
        this.showTree = true;
        this.showPrint = true;
        this.viewType = view;
        this.ManagerError = '';
        document.getElementById('list-container').classList.remove("toogleManager");
      
        if (view === 'directReport') {
          this.filteredItems = [];
          this.managerTree = this.allEmployees.filter(option => option.ReportingManagerID === this.ManagerId);
          this.employeegenerate();
        } else if (view === 'managerHierarchy') {
          this.managerTree = [];
          this.employeegenerate();
          // this.filteredItems = this.allEmployees.filter(item => item.ReportingManagerID === this.ManagerId);
          this.filteredItems = this.managerHierarchy.filter(item => item.ReportingManagerID === this.ManagerId);
          // Create the initial list element
          const list = document.createElement('ul');
          // Create and add the list items based on the API data
          this.filteredItems.forEach(itemData => {
            const item = document.createElement('li');
            const contentDiv = document.createElement('div');
            contentDiv.className = 'profile-container'; // You can add a CSS class for styling if needed
            // Check if the item's RepList has a length greater than 0
            if (itemData.RepList && itemData.RepList.length > 0) {
              const arrowSpan = document.createElement('span');
              arrowSpan.classList.add('arrow-icon'); // You can define a CSS class for the arrow icon
              contentDiv.appendChild(arrowSpan);
            }
            const image = document.createElement('img');
            image.src = itemData.PhotoPath;
            image.alt = itemData.EmployeeName;
            image.width = '20';
            image.height = '20';
            contentDiv.appendChild(image);
            const name = document.createTextNode(itemData.EmployeeName);
            contentDiv.appendChild(name);
            item.appendChild(contentDiv);
            contentDiv.addEventListener('click', () => {
              item.classList.toggle('ui-state-active');
              const clickedEmployee = item.textContent;
              // Check if the clicked item has a different ID than the current list
              const filteredID = this.allEmployees.filter(item => item.EmployeeName === clickedEmployee);      
              //const filteredID = this.managerHierarchy.filter(item => item.EmployeeName === clickedEmployee);
              try {
                const EmpID = filteredID[0].EmployeeID;
                const currentEmpName = filteredID[0].EmployeeName;
                const filtered = { id: EmpID, name: currentEmpName };
                this.showChild(filtered);
              } catch (error) {
                //console.log(error);
              }
            });
            list.appendChild(item);
          });
          // Update the current list ID and add the new list to the container
          const container = document.querySelector('#list-container');
          container.innerHTML = '';
          container.appendChild(list);
        }
      }
      

    async getImageAsBase64(imageUrl) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', imageUrl, true);
            xhr.responseType = 'blob';

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const blob = xhr.response;
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64data = reader.result;
                        resolve(base64data);
                    };
                    reader.readAsDataURL(blob);
                } else {
                    reject(new Error('Image load error'));
                }
            };

            xhr.send();
        });
    }

    async employeeGenerateChart() {
      if (this.searchTerm == '' || this.searchTerm == undefined) {
        this.EmployeeError = "Employee is required.";
        return;
      }
    
      if (this.filteredResults.length < 1) {
        const result = await this.dialogService.open({
          viewModel: Errormodal,
          model: "1",
        }).whenClosed(response => response);
        if (result.wasCancelled) {
          this.searchTerm = '';
          return;
        }
      } else {
        this.showPrint = true;
        this.clicked = true;
        this.employeeTree = [];
        this.EmployeeError = '';
        //document.getElementById('supervisor-container').classList.add("toogleEmployee");
        const employee = this.selectedEmployee;
        this.employeeTree.push(employee);
        let selectedId = parseInt(employee.ReportingManagerID);
        let foundManager = this.allEmployees.find(employee => employee.EmployeeID === selectedId);
        while (foundManager) {
          this.employeeTree.push(foundManager);
          selectedId = parseInt(foundManager.ReportingManagerID);
          foundManager = this.allEmployees.find(employee => employee.EmployeeID === selectedId);
        }
      }
    }
    
    
      async showChild(emp) {
        //const childTree = this.allEmployees.filter(item => item.ReportingManagerID === emp.id);
        const childTree = this.managerHierarchy.filter(item => item.ReportingManagerID === emp.id);
        console.log(childTree);
        // Create a new nested list element
        const nestedList = document.createElement('ul');
        // Create and add the list items based on the API data
        childTree.forEach(itemData => {
          const item = document.createElement('li');
          const contentDiv = document.createElement('div');
          contentDiv.className = 'profile-container';
          // Check if the item's RepList has a length greater than 0
          if (itemData.RepList && itemData.RepList.length > 0) {
              const arrowSpan = document.createElement('span');
              arrowSpan.classList.add('arrow-icon'); // You can define a CSS class for the arrow icon
              contentDiv.appendChild(arrowSpan);
          }
          const image = document.createElement('img');
          // image.setAttribute('crossorigin','*');
          image.src = itemData.PhotoPath;
          image.alt = itemData.EmployeeName;
          image.width = '20';
          image.height = '20';
          contentDiv.appendChild(image);
          const name = document.createTextNode(itemData.EmployeeName);
          contentDiv.appendChild(name);
          item.appendChild(contentDiv);
          contentDiv.addEventListener('click', () => {
            item.classList.toggle('ui-state-active');
            const clickedEmployee = item.textContent;
            // Check if the clicked item has a different ID than the current nested list 
            const filteredID = this.allEmployees.filter(item => item.EmployeeName === clickedEmployee);
            //const filteredID = this.managerHierarchy.filter(item => item.EmployeeName === clickedEmployee);
            try{
              const EmpID = filteredID[0].EmployeeID;
              const currentEmpName = filteredID[0].EmployeeName;
              const filtered = {id:EmpID,name:currentEmpName};
              this.showChild(filtered);
            }catch(error){
              //console.log(error);
            }
          });
            nestedList.appendChild(item);
          });
        //}   
        // Find the clicked list item using its text content
        const listItems = document.querySelectorAll('li');
        let container;
        listItems.forEach(listItem => {
          if (listItem.textContent === emp.name) {
            container = listItem;
          }
        });
        // Add the new nested list to the clicked list item
          container.appendChild(nestedList);
      }
      
      
      get reversedEmployees() {
        // Create a copy of the original employees array to avoid modifying it directly
        const copiedEmployees = [...this.employeeTree];
        // Reverse the order of the employees array
        const reversedOrder = copiedEmployees.reverse();
        return reversedOrder;
      }
    
      isLastElement(index) {
        return index === this.employeeTree.length - 1;
      }

      async handleSearch() {
        if (!this.searchTerm) {
          return;
        }
        this.filteredResults = this.allEmployees.filter(employee =>
          employee.EmployeeName.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        this.showDropdown = true;
      }
    
      async handleSelection(employee) {
        //this.showTree = false;
        this.searchTerm = employee.EmployeeName;
        this.selectedEmployee = employee;
        this.EmployeeError = '';
        this.showDropdown = false; // Close the dropdown after selection
      }
    
      closeDropdown() {
        this.showDropdown = false; // Close the dropdown when clicking outside it or the input field
      }
      
      employeegenerate(){
       const viewValue = document.querySelector('#viewType').value;
          if(viewValue === 'directReport'){
              document.getElementById('popup').classList.add("directReport");
              document.getElementById('popup').classList.remove("managerHierarchy");
          }
          if(viewValue === 'managerHierarchy'){
              document.getElementById('popup').classList.add("managerHierarchy");
              document.getElementById('popup').classList.remove("directReport");
          }
          this.ManagerName = this.searchManager;
      }

    employeegenerate2(){
        this.showPrint = true;
        this.clicked = true;
        const employee = this.selectedEmployee;
        this.employeeTree.push(employee);

        let selectedId = parseInt(employee.ReportingManagerID);
        let foundManager = this.allEmployees.find(employee => employee.EmployeeID === selectedId);

        while (foundManager) {
            this.employeeTree.push(foundManager);
            selectedId = parseInt(foundManager.ReportingManagerID);
            foundManager = this.allEmployees.find(employee => employee.EmployeeID === selectedId);
        }
    }
    
    generatePDF1()
      {
        if(this.employeeOrDepartment)
        {
          this.views =this.employeeOrDepartment;
          console.log(this.views);
          if(this.views =="employee")
          {
          this.ViewByManager =1;
          }
          if(this.views =="department")
          {
          this.ViewByManager =2;
          }
        }
        
      if(this.ViewByManager == 1)
      {
        // if(this.ManagerId )
        // {
          this.EmployeeId=this.ManagerId 
          
        //}
      }
      if(this.ViewByManager == 2)
      {
        this.EmployeeId =this.selectedEmployee.EmployeeID;
      }

        if(this.view)
        {
          if(this.view == "directReport")
          {
          this.ChartViewID =1;
          }
          if(this.view == "managerHierarchy")
          {
          this.ChartViewID=2;
          }
        }

        // const data = {
        //   EmployeeId:this.ID,
        //   ChartViewID:this.view,
        //   ViewByManager:this.views,
        //   };
        //   console.log(data);

          this.organizationService.orgchart(this.EmployeeId,this.ChartViewID,this.ViewByManager).then(data=>
            {
                this.getHolidayList  =data;
                console.log(data);
            });

          
            // this.organizationService.orgchart(this.EmployeeId, this.ChartViewID, this.ViewByManager)
            //   .then(response => {
            //     // Create a blob from the response data
            //     console.log(data);
            //     const blob = new Blob([response], { type: 'application/pdf' });
          
            //     // Create a URL for the blob
            //     const url = window.URL.createObjectURL(blob);
          
            //     // Create a link element to trigger the download
            //     const a = document.createElement('a');
            //     a.href = url;
            //     a.download = 'Chart.pdf';
            //     document.body.appendChild(a);
            //     a.click();
          
            //     // Clean up the URL object
            //     window.URL.revokeObjectURL(url);
            //   })
            //   .catch(error => {
            //     console.error('Error downloading PDF:', error);
            //   });
          
            // this.organizationService.orgchart(this.EmployeeId, this.ChartViewID, this.ViewByManager)
            // .then(response => {
            //   // Check if the response is a Blob (PDF)
            //   if (response instanceof Blob) {
            //     // Create a URL for the blob
            //     const url = window.URL.createObjectURL(response);
          
            //     // Create a link element to trigger the download
            //     const a = document.createElement('a');
            //     a.href = url;
            //     a.download = 'Chart.pdf';
            //     document.body.appendChild(a);
            //     a.click();
            //     console.log('Response type:', response.type);
            //     // Clean up the URL object
            //     window.URL.revokeObjectURL(url);
            //   } else {
            //     console.error('Response is not a Blob. Expected a PDF.');
            //     console.log('Response type:', response);
            //   }
            // })
            // .catch(error => {
            //   console.error('Error downloading PDF:', error);
            // });
          
        
      }

      resetData(){
        window.location.reload();
        this.showTree = false;
        this.viewType=null;
        this.searchManager = '';
        this.view = '';
        this.searchTerm = '';
        this.employeeTree=[];
        document.getElementById('popup').classList.remove("directReport");
        document.getElementById('popup').classList.remove("managerHierarchy");
        this.showPrint = false;
      }

      resetemployee() {
        window.location.reload();
        this.employeeChildTree = [];
        this.employeeTree = [];
        this.showTree = false;
        this.showPrint = false;
        this.searchManager = '';
        this.view = '';
        this.searchTerm = '';
        if (this.clicked) {
        this.clicked = false;
      this.element.disabled = false;
        }
      }

      async generatePDF() {
        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        // Set page size and orientation
        doc.setPage('a4', 'portrait');
          const popupContent = document.getElementById('popup');
          const spanElements = document.querySelectorAll('.arrow-icon');
          if(spanElements){
              spanElements.forEach(span => {
                  span.style.display = 'none';
              });
          }

          // const downloadButton = popupContent.querySelector('.xls-down4load');
          // if (downloadButton) {
          //     downloadButton.remove();
          // }
          // const elementsWithBackground = popupContent.querySelectorAll('.list-container li.parenttree > .profile-container');
          // elementsWithBackground.forEach(element => {
          //     const style = document.createElement('style');
          //     style.innerHTML = '.profile-container:after { background-image: none !important; }';
          //     element.appendChild(style);
          // });
         await html2pdf().from(popupContent).set({
              margin: [28,28,28,40],
              filename: 'hierarchyChart.pdf',
              image: { type: 'png', quality: 0.95 },
              html2canvas: {
                  useCORS:true},
              jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          }).save();

          if(spanElements){
              spanElements.forEach(span => {
                  span.style.display = 'block';
              });
          }
          // Wait for all images to load
      }

      cancel() {
        this.hierarchyData = [];
        this.controller.cancel();
     }

     addToRecentItems(){
      this.leavemanagementService.AddToRecentItems(this.empid,this.SiteMapId).then(data=>
       {

       });
   }
  
}
