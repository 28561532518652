import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from '../../shared/services/loginservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {JwtService} from "../../shared/services/jwtservice";
import { App } from '../../app';
@inject(LoginService, SharedState, Router, ValidationControllerFactory,HttpClient,JwtService,App)
export class ForgotComponent {
 
  type = '';
  errors = null;
  username = '';
  emailId = '';
  emailMessage=null;
  usernameMessage=null;
  forgotpassword={name:'forgotpassword'};
  forgotPasswordMatch = (a, b) => a.name === b.name;
    routerView = "main-content";
  
  constructor(loginService, sharedState, router, controllerFactory,httpClient,JwtService,App) {
    this.loginService = loginService;
    this.sharedState = sharedState;
    this.router = router;
    this.httpClient = httpClient;
    this.controller = controllerFactory.createForCurrentScope();
      this.jwtService = JwtService;
      this.app=App;

    ValidationRules
    .ensure('username').required()
    .ensure('emailId').email().required().withMessage('Email is required.')
    .on(this);
    
  }

  attached(){
      document.querySelector('router-view').classList.remove(this.routerView);
      this.app.updatePropertyValue(false);
      this.loginService.bindImages().then(data=>{
      this.imageSlider = data;
      this.bannerUrl=config.img_url;
    });
    let i =1;
    setTimeout(()=>{
      let image=document.querySelectorAll(".banner-image");
      image[0].style.opacity='1';
      setInterval(function () {
        image.forEach((img,i)=>{
          img.style.opacity='0';
        });
        if(i === image.length){
          i=0;
        }
        console.log(image[i]);
          image[i].style.opacity='1';
          i++;
      },5000);
    },200)

    // static slider css remove when banner images dynamic //
    const images = document.querySelectorAll('.banner-image img');
    let currentIndex = 0;

    function fadeInImage(index) {
        images[index].classList.add('active');
    }

    function fadeOutImage(index) {
        images[index].classList.remove('active');
    }

    function nextImage() {
        fadeOutImage(currentIndex);
        currentIndex = (currentIndex + 1) % images.length;
        fadeInImage(currentIndex);
    }

    setInterval(nextImage, 3000); // Change image every 3 seconds
    fadeInImage(currentIndex); // Initial fade in
    // static slider css remove when banner images dynamic //
    
  } 

  determineActivationStrategy() {
    return activationStrategy.replace;
  }
  
  activate(params, routeConfig) {
    this.type = routeConfig.name;
  }
  
  submit()
      {
        this.errors = null;
        this.controller.validate()
        .then(result => {
          if (result.valid) {
           
              let forgotPassword=this.forgotpassword;
                let forgot ;
              if (forgotPassword.name ==='forgotpassword'){
                  forgot = {
                      "UserName": this.username,                                      
                  };
              }else if(forgotPassword.name ==='forgotusername'){
                  forgot = {
                      "EmailID": this.emailId,
                     
                  };
              }else{
                forgot = {
                  "EmailID": this.emailId,                  
              };
              }

            this.loginService.NeedHelp(this.type,forgot)
            .then(data => 
              {
                
                if(data.EmployeeID > 0){
                 this.router.navigateToRoute('forgotpassword')
                 console.log(this.forgotpassword);
                      let forgotPassword=this.forgotpassword;
                      let forgot1 ;
                      if (forgotPassword.name ==='forgotpassword'){
                          forgot1 = {                    
                            "Message": this.usernameMessage='Password has been sent to your registered email.',                 
                            };
                          }
                      else if(forgotPassword.name ==='forgotusername'){
                          forgot1 = {
                            "Message": this.emailMessage='Username is sent to your email address.',
                          };
                        }
                        else{
                            forgot1 = {                 
                            "Message": this.usernameMessage=null 
                          };
                        }
                 
                }    
                else{
                  // this.controller.addError("Invalid details"); 
                   this.errors='Invalid details';    
                   this.usernameMessage=null;
                   this.emailMessage=null;
                }        
                
              }
              )
            .catch(promise => {
              promise.then(err => this.errors = err.errors)
            });              
           
         }
       })
      }

    
      usernameClicked() {
        this.emailMessage=null;
        this.errors=null;
        return true;
      }
      emailClicked() {
        this.usernameMessage=null;
        this.errors=null;
        return true;
      }
      // textbox1Clicked()
      // {
      //   this.emailMessage=null;
      //   return true;
      // }
      // textbox2Clicked()
      // {
      //   this.usernameMessage=null;
      //   return true;
      // }
 }

 
 
